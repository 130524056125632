import { createApp, h } from 'vue';
import BootstrapVue from 'bootstrap-vue-3';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { version } from '../package.json';
import vco from 'v-click-outside';
import VueApexCharts from 'vue3-apexcharts';
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar';
import { createI18n } from 'vue-i18n';
import DropZone from 'dropzone-vue';
import VueSignaturePad from 'vue3-signature-pad';

import CKEditor from '@ckeditor/ckeditor5-vue';
import VueGravatar from 'vue3-gravatar';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
// element-plus
import 'element-plus/theme-chalk/index.css';
import installElementPlus from './plugins/element';
//Geo server plugin
import vfmPlugin from 'vue-final-modal';
// Vue Attachments
import VueAttachment from 'vue-attachment';
// As a plugin
import VueGoodTablePlugin from 'vue-good-table-next';

// import geoServerSocket from './plugins/geoserver-socket'
import Simplebar from './components/SimpleBar';
import router from './router';
import App from './App.vue';
import axios from './helpers/api';
import DomainDetector from './plugins/domainDetector';
import store from '@/state/store';
import PrimeVue from 'primevue/config';
import Tree from 'primevue/tree';
import vTree from "vue3-tree";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import Vue3Tour from 'vue3-tour';
import ToastService from 'primevue/toastservice';
import helpers from './helpers/custom';
import { createPinia, setActivePinia } from 'pinia' // Import
import { createPersistedState } from 'pinia-plugin-persistedstate'
//import Vue3Geolocation from 'vue3-geolocation';

import 'vue3-tour/dist/vue3-tour.css';

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css';

import 'simplebar/dist/simplebar.min.css';
import '@/assets/scss/app.scss';
// Sweet alerts
import 'sweetalert2/dist/sweetalert2.min.css';


// temporary fix for css file not pulling through, its a known issue: https://github.com/vueform/multiselect/issues/325.
// import '@vueform/multiselect/themes/default.css';
import './assets/multiselect-default.css';

import 'dropzone-vue/dist/dropzone-vue.common.css';

import 'vue-search-input/dist/styles.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import './registerServiceWorker';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import SearchInput from 'vue-search-input';
// import BpmnJS from '@phl/bp-js/dist/bp-navigated-viewer.production.min';
// import BpmnModeler from '@phl/bp-js';
// import BpmnViewer from '@phl/bp-js';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { DateTime } from 'luxon';
import eventBus from './event-bus';

// Globally import grid layout plus
import { GridLayout, GridItem } from 'grid-layout-plus';

// load font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { userActivityLoggerService } from '@/services/userActivityLogger.service'
import { v4 as uuidv4 } from 'uuid';

//Context Menu
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
import { last } from 'lodash';

library.add(fab);
library.add(fas);
library.add(far);

//const newrelic = require('@newrelic');
//const nrconfig = require('./newrelic');
if (process.env.VUE_APP_BUGSNAG_ENABLED === 'true') {
    Bugsnag.start({
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
    });
}

const bugsnagVue = Bugsnag.getPlugin('vue');

// setup Localisation
const messages = { en: require('./locales/en.json') };
const app = createApp({
    render: () => h(App),
});

const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});
installElementPlus(app);
if (window.Cypress) {
    // Add `store` to the window object only when testing with Cypress
    window.__store__ = store;
}
const pinia = createPinia();
pinia.use(createPersistedState({ auto: true })); // set auto persistance of state to store 
app.use(pinia) // Create the root store
setActivePinia(pinia) // Use pinia as the store
app.use(ContextMenu);
app.use(router);
app.use(store);
app.use(i18n);
app.component('VueSlideBar', VueSlideBar);
app.use(BootstrapVue);
app.use(vco);
app.component('apexchart', VueApexCharts);
app.use(VueSweetalert2);
app.use(DomainDetector);
app.component('simplebar', Simplebar);
app.use(VueGoodTablePlugin);
app.component(DropZone);
app.use(VueGravatar);
app.use(VueAttachment);
app.use(vfmPlugin);
app.use(CKEditor);
app.use(PrimeVue);
app.use(Vue3Tour);
app.use(ToastService);
app.use(SearchInput);
app.use(Toastify);
app.component('Datepicker', Datepicker);
app.component('RecycleScroller', RecycleScroller);
app.component('font-awesome-icon', FontAwesomeIcon);

// app.config.globalProperties.$bpmnViewer = new BpmnViewer();
// app.config.globalProperties.$bpmnModeler = new BpmnModeler();
// app.config.globalProperties.$bpmnInstance = new BpmnJS();


//initiate global components for grid layout
app.component('GridLayout', GridLayout)
app.component('GridItem', GridItem)

app.component('Tree', Tree);
app.component('vTree', vTree)
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Button', Button);
app.component('Tag', Tag);

app.use(VueSignaturePad);
//app.use(Vue3Geolocation);
app.use(bugsnagVue);
// socket plugin
//app.use(geoServerSocket,{store:store,"model":"socket"})
//socket plugin

const token = localStorage.getItem('token');
axios.interceptors.request.use(
    config => {

        //normal set-up
        let tenantId = '';
        if (JSON.parse(localStorage.getItem('user'))) {
            tenantId = JSON.parse(localStorage.getItem('user')).selectedTenant || JSON.parse(localStorage.getItem('user')).tenants[0];
        }

        const impersonationStatus = localStorage.getItem('impersonationStatus');
        //when we have completed impersonation restore the tenantId to the admin user's last selected tenant
        if (impersonationStatus == 'ended') {
            tenantId = localStorage.getItem('userTenant');
        };
        config.headers['x-tenant-id'] = `${tenantId}`;
        config.headers['x-ui-version'] = `${version}`;
        return config;

    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {

        const status = 500 || error.response.status;
        switch (status) {
            case 401: {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('tenants');
                localStorage.removeItem('refreshToken');
                delete axios.defaults.headers.common.Authorization;
                if (window.location.href.indexOf('login') == -1) {
                    return router.go('/login');
                }
                break;
            }
            default: {
                return Promise.reject(error);
            }
        }
    },
);

//axios.defaults.headers.common.Authorization = `Bearer ${token}`;

app.config.globalProperties.$geoInterval = {};

app.config.globalProperties.$isAdmin = store.getters['user/isAdmin'];
app.config.globalProperties.$isLoanOfficer = store.getters['user/isLoanOfficer'];
app.config.globalProperties.$isCreditOfficer = store.getters['user/isCreditOfficer'];
app.config.globalProperties.$isTeamLeader = store.getters['user/isTeamLeader'];
app.config.globalProperties.$getUser = store.getters['user/getUser'];
app.config.globalProperties.emitter = eventBus;

app.config.globalProperties.$filters = {
    formatDateTime: helpers.formatDateTime,
    formatDateTimeISO: helpers.formatDateTimeISO,
    formateTimePast: helpers.formateTimePast,
    formatDecimal: helpers.formatDecimal,
    formatLatLong: helpers.formatLatLong,
};

// var retryGPS = true;
// var retryTimeout = 60000;
// var retryCount = 0;



router.isReady().then(() => {
    app.mount('#app');
})
    .then(() => {

        function haversineDistance(lat1, lng1, lat2, lng2) {
            const toRadians = (degrees) => degrees * (Math.PI / 180); // Converts degrees to radians

            const R = 6371000; // Radius of the Earth in meters
            const dLat = toRadians(lat2 - lat1); // Delta latitude in radians
            const dLng = toRadians(lng2 - lng1); // Delta longitude in radians

            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                Math.sin(dLng / 2) * Math.sin(dLng / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const distance = R * c; // Distance in meters

            return Math.abs(distance); // Return absolute distance

        };
        //Now that the app is mounted, start trying to get the GPOS position, and escalate the retry's until it's tried a few times and fails..
        async function tryGetGPS() {
            var options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 5000,
            };
            let retryTimeout = 300000; //60 * 1000 * 15;
            let retryGPS = true;
            let retryCount = 0;
            let userId = null;
            let previousPosition = null;
            let lastUserGPSData = {};
            let user = JSON.parse(localStorage.getItem('user'));



            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position.coords.latitude != 0 && position.coords.longitude != 0) {
                        //position successful
                        previousPosition = JSON.parse(localStorage.getItem('lastGpsPosition'));

                        lastUserGPSData = {
                            "latitude": position.coords.latitude,
                            "longitude": position.coords.longitude,
                            "accuracy": position.coords.accuracy,
                            "update": DateTime.now().toISO()
                        }

                        // get the userID

                        localStorage.setItem('lastGpsPosition', JSON.stringify(lastUserGPSData));
                        // Only log position to DB if change is greater than 50 meters        
                        if (user) {
                            userId = user.Id;

                            if (previousPosition && lastUserGPSData) {
                                let distance = haversineDistance(lastUserGPSData.latitude, lastUserGPSData.longitude, previousPosition.latitude, previousPosition.longitude);
                                //console.log('Distance Travelled: ', distance);
                                if (Number(distance) >= 50) {
                                    userActivityLoggerService.createTracking(uuidv4(), userId, 'user_position');
                                }
                            }

                        }


                        retryGPS = true;
                        retryTimeout = 30000;  //60 * 1000 * 15;
                        retryCount = 0;
                    } else {
                        retryGPS = true;
                    }
                },
                (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            break;

                        case error.POSITION_UNAVAILABLE:
                            retryGPS = true;
                            retryCount += 1;
                            break;

                        case error.TIMEOUT:
                            retryGPS = true;
                            retryCount += 1;
                            break;

                        case error.UNKNOWN_ERROR:
                            retryGPS = true;
                            retryCount += 1;
                            break;
                    }
                },
                options
            );



            if (retryGPS && retryCount <= 5) {
                retryTimeout = 30000;
                setTimeout(tryGetGPS, retryTimeout);
            } else {
                retryTimeout = 60000 * 15;
                setTimeout(tryGetGPS, retryTimeout);
            }


        }
        tryGetGPS();
        //start trying to get location
    }).catch((err) => { console.log(err); });

//Leflet Edit
//import "leaflet-editable" ;
