import axios from '../helpers/api';
import helper from '../helpers/custom/index';
const { v4: uuidv4 } = require('uuid');
import db from './pouchDb';
import _ from 'lodash';
import { userActivityLoggerService } from '@/services/userActivityLogger.service';
const { compareSync } = require('bcryptjs');
import { DateTime } from 'luxon'; 
// const { version } = require('./../../package.json');

export const userService = {
    login,
    logout,
    getAll,
    getAlldeleted,
    getUserByEmail,
    getUserById,
    getUserByIdWithHighlevelTenants,
    getUsersByTeamId,
    createUser,
    updateUser,
    forgotPassword,
    resetPassword,
    me,
    deleteUser,
    unDeleteUser,
    userExists,
    fetchAuthUrl,
    verifyToken,
    authenticateApi,
    completeTour,
    getUsersByIds,
    biometricAuth,
    getUserByRefreshToken,
    getUsersByTenantId,
    changePassword,
    verifyUser,
    validateToken,
    countNewUsers,
    impersonate,
    getPasswordChangeHistory,
    getLoginHistoryCount,
    logImpersonation,
    getLoginReport,
};

async function getLoginReport(startDate, endDate, page, size, sortField = 'startDate', sortOrder = 'DESC', search = '%%') {
    page = page || 1;
    size = size || 1000000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();

    try {
        const res = await axios.get(`/api/users/loginreport?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`);

        return res;
    } catch (error) {
        console.log(error);
    }
}

async function getLoginHistoryCount() {

    try
    {
        if (navigator.onLine) {
            const res = await axios(`/api/users/dashboard/login-history`);
            return res.data;
        }
        return null;
    } catch(err){
        return err;
    }
   

}

async function getPasswordChangeHistory(startDate, endDate, page = 1, size = 1000000, sortField = 'dateCreated', sortValue = 'desc') {
    page = page || 1;
    size = size || 1000000;
    sortField = sortField || 'dateCreated';
    sortValue = sortValue || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -365 }).toFormat('yyyy-MM-dd');
    endDate = endDate || DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd');
  
        const res = await axios.get(
            `/api/users/password_changed_history?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortValue=${sortValue}`,
        );
        return res.data;
  
}

async function login(email, password) {
    if (navigator.onLine) {
        const res = await axios.post('/api/auth/login', { email, password });
        return res;
    } else {
        try {
            const res = await db.authDB.allDocs({
                include_docs: true,
            });
            // let response = {};
            for (let i = 0; i < res.rows.length; i++) {
                let obj = res.rows[i].doc;
                if (obj && obj.user) {
                    if ((obj.user.userName && obj.user.userName == email) || (obj.user.emailAddress && obj.user.emailAddress == email)) {
                        const match = await compareSync(password, obj.user.passwordHash);
                        if (match) {
                            //hash the password and check it
                            //TODO: update queries to return the actual permissions
                            // obj.user.permissions = [];
                            // obj.user.rolePermissions = [];
                            obj.user.token = 'OFFLINE_TOKEN_PLACEHOLDER';
                            let response = {
                                status: 200,
                                statusText: 'OK',
                                offline: true,
                                data: {
                                    success: true,
                                    data: obj.user,
                                    user: obj.user,
                                },
                                result: {
                                    success: true,
                                    message: 'Users retrieved successfully.',
                                },
                            };
                            return response;
                        } else {
                            return { error: 'Invalid credentials' };
                        }
                    }
                }
            }
            // return response;
        } catch (error) {
            return { status: 203, statusText: 'User not found', error: error };
        }
    }
}

//log-in a user by checking their username, no password checks 
async function impersonate(userName){
    if (navigator.onLine) {
        try{
            const res = await axios.post(`/api/auth/impersonate`, {userName});
            return res;
        }catch(e){
            console.log(e);
        };
    }else{
        try {
            const res = await db.authDB.allDocs({
                include_docs: true,
            });
            for (let i = 0; i < res.rows.length; i++) {
                let obj = res.rows[i].doc;
                if (obj && obj.user) {
                    if ((obj.user.userName && obj.user.userName == email) || (obj.user.emailAddress && obj.user.emailAddress == email)) {
                        //replace password match with userName match
                        const match = await compareSync(userName, obj.user.userName);
                        if (match) {
                            obj.user.token = 'OFFLINE_TOKEN_PLACEHOLDER';
                            let response = {
                                status: 200,
                                statusText: 'OK',
                                offline: true,
                                data: {
                                    success: true,
                                    data: obj.user,
                                    user: obj.user,
                                },
                                result: {
                                    success: true,
                                    message: 'User retrieved successfully.',
                                },
                            };
                            return response;
                        } else {
                            return { error: 'Impersonation Failed' };
                        }
                    }
                }
            }
            // return response;
        } catch (error) {
            return { status: 203, statusText: 'Impersonation Failed, User Not Found', error: error };
        }
    }
}

async function logImpersonation (adminId, impersonateId,state){
    try{
         await axios.post(`/api/auth/logImpersonation`, {adminId, impersonateId,state});
        // return res;
    }catch(e){
        console.log(e);
    };
}

async function verifyToken(email, token) {
    const res = await axios.post('/api/auth/token', { email, token });
    return res.data;
}

async function logout() {
    // remove user from local storage to log user out

    let userId = '';

    // trackUsers Logout Location
    if (JSON.parse(localStorage.getItem('user'))) {
        try {
            userId = JSON.parse(localStorage.getItem('user')).Id;
            await userActivityLoggerService.createTracking(uuidv4(), userId, 'logout');
        } catch (e) {
            console.log(e);
        }

    }

    axios.get('/api/auth/logout');
    localStorage.removeItem('user');
    localStorage.removeItem('impersonationUser');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tenants');

    axios.defaults.headers.common.Authorization = 'Bearer ';

}

async function getAll(roleName = '', page = 1, size = 1000000, sortField = 'dateCreated', sortValue = 'desc', search = '%%') {
    if (navigator.onLine) {
        try{
            const usrId = JSON.parse(localStorage.getItem('user')).Id;
            const res = await axios.get(`/api/users?page=${page}&size=${size}&sortField=${sortField}&sortValue=${sortValue}&roleName=${roleName}&search=${search}&usrId=${usrId}`);       
            return res.data;
        }catch(err){
            console.log(err);
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
            });
            let users = [];
            res.rows.forEach(row => {
                if (row.doc.user) users.push(row.doc.user);
            });
            let temp = {
                data: { users: users },
                meta: {
                    order: {
                        sortBy: [
                            {
                                alias: sortField,
                                order: sortValue,
                            },
                        ],
                    },
                    paging: { pageNumber: page, pageSize: size, total: users.length },
                },
                result: {
                    success: true,
                    message: 'Users retrieved successfully.',
                },
            };
            // console.log("offline users:", temp);
            return temp;
        } catch (error) {
            // console.log(error);
        }
    }
}

async function getAlldeleted(roleName = '', page = 1, size = 1000000, sortField = 'dateCreated', sortValue = 'desc', search = '%%') {


    if (navigator.onLine) {
        const res = await axios.get(
            `/api/users/deleted?page=${page}&size=${size}&sortField=${sortField}&sortValue=${sortValue}&roleName=${roleName}&search=${search}`,
        );
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
            });
            let users = [];
            res.rows.forEach(row => {
                if (row.doc.user) users.push(row.doc.user);
            });
            let temp = {
                data: { users: users },
                meta: {
                    order: {
                        sortBy: [
                            {
                                alias: sortField,
                                order: sortValue,
                            },
                        ],
                    },
                    paging: { pageNumber: page, pageSize: size, total: users.length },
                },
                result: {
                    success: true,
                    message: 'Users retrieved successfully.',
                },
            };
            // console.log("offline users:", temp);
            return temp;
        } catch (error) {
            // console.log(error);
        }
    }
}

async function me(id) {
    try {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.refreshUserDb();
        }

        if (navigator.onLine) {
            const res = await axios(`/api/users/me/${id}`);
            return res.data;
        } else {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let response = {};
            res.rows.forEach(row => {
                if (row.doc.user) {
                    if (row.doc.user.Id == id) {
                        response = {
                            data: { user: row.doc.user },
                            result: {
                                success: true,
                                message: 'Users retrieved successfully.',
                            },
                        };
                    }
                }
            });
            return response;
        }
    } catch (error) {
        return error;
    }
}

async function deleteUser(userIds) {
    try {
        const res = await axios.delete('/api/users/delete', { data: { userIds: userIds } });

        return res.data;
    } catch (error) {
        return error;
    }
}

async function unDeleteUser(userIds) {
    try {

        const res = await axios.post('/api/users/undelete', { data: { userIds: userIds } });
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUserById(id) {
    try {
        const res = await axios(`/api/users/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUserByIdWithHighlevelTenants(id) {
    try {
        const res = await axios(`/api/users/detailswithhighleveltenants/${id}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUsersByTenantId(id) {
    try {
        const res = await axios(`/api/users/bytenantid/${id}`);

        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUsersByTeamId(id) {
    try {
        const res = await axios(`/api/users/by-team-id/${id}`);

        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUsersByIds(ids) {
    try {
        const res = await axios(`/api/users/list/${ids}`);

        return res.data;
    } catch (error) {
        return error;
    }
}

async function getUserByEmail(email) {
    try {
        const res = await axios(`/api/users/by-email/${email}`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function userExists(email, user, mobileNumber) {
    try {
        const res = await axios.post('/api/users/exist', {
            emailAddress: email || null,
            userName: user || null,
            mobile: mobileNumber || null,
        });
        return res.data;
    } catch (error) {
        return error;
    }
}

async function countNewUsers() {
    try {
        const res = await axios(`/api/users/dashboard/new-users`);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function createUser(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    const res = await axios.post('/api/users', data);
    return res.data;
}

async function updateUser(id, data) {
    try {
        const res = await axios.put(`/api/users/${id}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
}
async function changePassword(id, data) {
    try {
        const res = await axios.put(`/api/users/change-password/${id}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function forgotPassword(data) {
    try {
        const res = await axios.post('/api/auth/forgot-password/', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function resetPassword(data) {
    try {
        const res = await axios.post('/api/users/reset-password/', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function verifyUser(data) {
    const res = await axios.post('/api/auth/verify', data);
    return res.data;
}

async function completeTour(data) {
    const res = await axios.post('/api/users/tour', data);
    return res.data;
}

async function authenticateApi(data) {
    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refreshToken);
}

async function fetchAuthUrl(data) {
    const res = await axios.get(`/api/auth/auth-url/${data}`);
    return res.data;
}

async function biometricAuth(refreshToken) {
    const res = await axios.post(`/api/auth/biometric`, { refreshToken });
    return res.data;
}

async function getUserByRefreshToken(refreshToken) {
    const res = await axios.get(`/api/auth/refresh/${refreshToken}`);
    return res.data;
}

async function validateToken(data) {
    const res = await axios.post('/api/auth/verification/', data);
    return res.data;
}
