import axios from '../helpers/api';
import db from '@/services/pouchDb';
import helper from '../helpers/custom/index';
const { v4: uuidv4 } = require('uuid');
import _ from 'lodash';
import { DateTime } from 'luxon';
import { version } from './../../package.json';


export const leadsService = {
    getLeadsByUserId,
    getLeads,
    getLeadAddressesByLeadId,
    getLeadDetails,
    deleteLead,
    createLead,
    searchLeads,
    updateLead,
    updateLeadStatus,
    assignLead,
    getLeadAssigned,
    generatePdf,
    resendConsentRequest,
};

async function resendConsentRequest(tenantId, leadId, mobileNumber) {
    try {
        const res = await axios.post(`/api/leads/resend-consent-request`, { tenantId, leadId, mobileNumber });
        return res.data;
    } catch (error) {
        return error;
    }
}

//generate pdf and download it
async function generatePdf(pdfData,pdfName) {

    try {
        const res = await axios.post(
            '/api/leads/generate-pdf',
            {
                pdfData,
            },
            {
                responseType: 'blob',
            }, 
        );

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = pdfName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        return res;

    } catch (error) {
        console.log('ERROR GENERATING PDF', error);
        return error;
    }

};

async function getLeadAssigned(leadId) {

    const res = await axios.get(`/api/leads/assignedto/${leadId}`);
    return res.data;

}
async function getLeadsByUserId(
    page = 1,
    size = 1000,
    sortField = 'dateCreated',
    sortOrder = 'DESC',
    filters = {
        search: '%%',
        dateRange: [DateTime.now().startOf('week').toFormat('yyyy-MM-dd HH:mm:ss.SSS'), DateTime.now().endOf('week').toFormat('yyyy-MM-dd HH:mm:ss.SSS')],
        dateType: ['consent', 'affordability', 'created'],
    },
) {
   
    let search = filters.search;

    /** Convert to a string */
    const formattedType = filters.dateType
                .map(item => `'${item}'`)
                .join(', ')
                .toString();

      
    

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/leads/tenant/getbyid?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}&startDate=${filters.dateRange[0]}&endDate=${filters.dateRange[1]}&dateType=${formattedType}`
            );

            if (res && res.data.result.success && res.data.data && !_.isEmpty(res.data.data)) {
                return res.data;
            } else {
                //an API error has occurred, do whatever....
                return res;
            }
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
            });

            let leads = [];

            res.rows.forEach(row => {
                // decide which leads to show based on search
                if (row.doc.lead && search != '%%') {
                    if (
                        (row.doc.lead.status == search)
                        || (row.doc.lead.firstName.toUpperCase().includes(search.toUpperCase()))
                        || (row.doc.lead.lastName.toUpperCase().includes(search.toUpperCase()))
                        || (row.doc.lead.idNumber.toUpperCase().includes(search.toUpperCase()))
                        || (row.doc.lead.contactNumber.toUpperCase().includes(search.toUpperCase()))
                        || (row.doc.lead.emailAddress.toUpperCase().includes(search.toUpperCase()))
                        || (row.doc.lead.source.toUpperCase().includes(search.toUpperCase()))
                        || (row.doc.lead.status.toUpperCase().includes(search.toUpperCase()))
                    ) {
                        leads.push(row.doc.lead);
                    }
                } else { // add all leads as no search string
                    if (row.doc.lead) {
                        leads.push(row.doc.lead)
                    }
                }
            });


            let pageNumber = page;
            const pageSize = size;
            const pages = Math.ceil(leads.length / size);
            if (pageNumber > pages) {
                pageNumber = pages;
            }
            const start = (pageNumber - 1) * pageSize;
            const end = start + pageSize;
            let total = leads.length;
            if (leads.length > 0) {
                leads = _.slice(leads, start, end);
            }


            let temp = {
                data: {
                    leads: leads,
                },
                meta: {
                    paging: {
                        pageNumber,
                        pageSize: size,
                        total: total,
                    },
                },
                result: {
                    success: true,
                    message: 'Leads retrieved successfully.',
                },
            };

            return temp;
        } catch (error) {
            console.log('LEADS BY USER ERROR', error)
            let temp = {
                data: {
                    leads: [],
                },
                meta: {
                    paging: {
                        pageNumber,
                        pageSize: size,
                        total: leads.length,
                    },
                },
                result: {
                    success: false,
                    message: 'Error retrieving leads. ' + error,
                },
            };
            return temp;
        }
    }


}
//** Returns ALL addresses associated with a lead */
async function getLeadAddressesByLeadId(
    leadId = null,
) {

    const res = await axios.get(
        `/api/leads/addresses-by-lead/${leadId}`,
    );
    return res.data;

}

async function deleteLead(data) {
    try {
        const res = await axios.post('/api/leads/delete', { id: data });
        return res.data;
    } catch (error) {
        return error;
    }
}

async function getLeads(page, size, sortField, sortOrder) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';

    try {
        if (navigator.onLine) {
            const res = await axios.get(`/api/leads?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`);
            return res.data;
        }
        else {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
            });

            let leads = [];
            res.rows.forEach(row => {
                if (row.doc.lead) {
                    let lead = row.doc.lead;
                    leads.push(lead);
                }
            });

            let pageNumber = page;
            const pageSize = size;
            const pages = Math.ceil(leads.length / size);
            if (pageNumber > pages) {
                pageNumber = pages;
            }
            const start = (pageNumber - 1) * pageSize;
            const end = start + pageSize;
            let total = leads.length;
            if (leads.length > 0) {
                leads = _.slice(leads, start, end);
            }

            let temp = {
                data: {
                    leads: leads,
                },
                meta: {
                    paging: {
                        pageNumber,
                        pageSize: size,
                        total: total,
                    },
                },
                result: {
                    success: true,
                    message: 'Leads retrieved successfully.',
                },
            };

            return temp;
        }
    }



    catch (error) {
        return error;
    }
}

async function getLeadDetails(id) {

    try {
        if (navigator.onLine) {
            const res = await axios.get(`/api/leads/${id}`);
            return res.data;
        }
        else {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const { rows } = await db.userDB.allDocs({
                include_docs: true
            });
            let lead = null;
            rows.forEach(row => {
                if (row.doc.lead && row.doc.lead.Id == id) {
                    lead = row.doc.lead;
                }
            });

            let temp = {
                data: [lead],
                result: {
                    success: true,
                    message: 'Lead Detail retrieved successfully.',
                },
            };
            return temp;
        }
    }
    catch (error) {
        return error;
    }

}

async function updateLead(id, data) {

    data.longitude = data.coordinates.lng || 0;
    data.latitude = data.coordinates.lat || 0;

    if (navigator.onLine) {
        try {

            const res = await axios.put(`/api/leads/${id}`, data);
            return res.data;
        } catch (error) {
            return error;
        }
    }
    else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.refreshUserDb();
        }

        let doc = await db.userDB.get(id);

        let lead = {
            Id: id,
            tenantId: data.tenantId,
            firstName: data.firstname,
            lastName: data.lastname,
            emailAddress: data.emailAddress,
            phonenumber: data.phonenumber,
            contactNumber: data.phonenumber,
            idNumber: data.idnumber,
            status: data.status,
            source: data.source,
            createdByUserId: doc.createdByUserId,
            addressId: data.addressId,
            consentStatus: 'NONE PROVIDED',
            consentDate: DateTime.now().toISO(),
            installmentAmount: 0,
            totalAffordability: 0,
            affordabilityOutcome: 'NONE',
            affordabilityDate: DateTime.now().toISO(),
            dateCreated: data.dateCreated,
            dateModified: data.dateModified,
            location: data.location,
            type: data.type,
            latitude: data.latitude,
            empNumber: data.empNumber,
            longitude: data.longitude,
        };

        await db.userDB.put(
            {
                _id: id,
                _rev: doc._rev,
                from: 'offline',
                lead: lead,
            },
            { force: true },
        );

        let changeId = uuidv4();
        await db.globalsDB.put({
            _id: changeId,
            change: {
                version: version,
                entityId: id,
                userId: helper.getUserId(),
                entityType: 'lead',
                dateModified: DateTime.now().toISO(),
                changeType: 'UPDATE',
            },
        });

        return {
            result: {
                success: true,
                message: 'lead updated successfully',
            },
            data: { data },
        };
    }

}

async function updateLeadStatus(id, data) {

    if (navigator.onLine) {
        try {

            const res = await axios.put(`/api/leads/status/${id}`, data);
            return res.data;
        } catch (error) {
            return error;
        }
    }
    else {
        if (!db.userDB || _.isEmpty(db.userDB)) {
            db.refreshUserDb();
        }

        const { rows } = await db.userDB.allDocs({
            include_docs: true
        });
        let lead = null;
        rows.forEach(row => {
            if (row.doc.lead && row.doc.lead.Id == id) {
                lead = row.doc.lead;
            }
        });

        if (lead != null) {
            lead.status = data.status;

            await db.userDB.put(
                {
                    _id: id,
                    _rev: doc._rev,
                    from: 'offline',
                    lead: lead,
                },
                { force: true },
            );

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    version: version,
                    entityId: id,
                    userId: helper.getUserId(),
                    entityType: 'lead',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'UPDATE',
                },
            });

            return {
                result: {
                    success: true,
                    message: 'lead updated successfully',
                },
                data: { data },
            };
        }
        else {
            return {
                result: {
                    success: false,
                    message: 'lead not found',
                },
                data: { data },
            };
        }

    }

}

async function createLead(data) {
    // because of off-line requirements actually insert the leadId,
    // dont' wait for database generated Id
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    data.longitude = data.coordinates.lng || 0;
    data.latitude = data.coordinates.lat || 0;
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/leads', data);
            return res;
        } catch (error) {
            console.log('ERROR CREATING LEAD ONLINE', error)
            return error;
        }
    }
    else {
        try {

            // create lead offline, address data is included in offline lead creation

            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }


            // get the user so can get the 
            const user = JSON.parse(localStorage.getItem('user')); //await db.userDB.get(data.createdByUserId);
          
            let userName = '';
            if (user) {
                userName = user.firstName + ' ' + user.lastName;
            }

            const { rows } = await db.userDB.allDocs({
                include_docs: true
            });

            // search through the offline leads and check email and phone number to see they are not entering a duplicate lead
            let leads = [];

            rows.forEach(row => {
                // decide which leads to show based on search
                try {
                    if (row.doc.lead && (row.doc.lead.contactNumber === data.phonenumber || row.doc.lead.emailAddress.toUpperCase() === data.emailAddress.toUpperCase())) {
                        leads.push(row.doc.lead);
                    }
                }
                catch (error) {
                    console.log('ERROR searching offline lead', error);
                }
            });

            if (leads.length > 0) {
                return {
                    status: 200,
                    data: {
                        result: {
                            success: false,
                            message: 'Unable to create lead, lead already exists.',
                        },
                        error: {
                            code: 400,
                            message: 'Duplicate email or phone number.',
                        },
                    },
                }
            }

            let lead = {
                Id: data.Id,
                tenantId: data.tenantId,
                firstName: data.firstname,
                lastName: data.lastname,
                emailAddress: data.emailAddress,
                phonenumber: data.phonenumber,
                contactNumber: data.phonenumber,
                idNumber: data.idnumber,
                status: 'New',
                source: 'UI',
                createdByUserId: data.createdByUserId,
                leadCreatedBy: userName,
                addressId: data.addressId,
                consentStatus: 'NONE PROVIDED',
                consentDate: DateTime.now().toISO(),
                installmentAmount: 0,
                totalAffordability: 0,
                affordabilityOutcome: 'NONE',
                affordabilityDate: DateTime.now().toISO(),
                dateCreated: data.dateCreated,
                dateModified: data.dateCreated,
                location: data.location,
                lineone: data.lineone,
                city: data.city,
                postcode: data.postcode,
                state: data.state,
                country: data.country,
                type: data.type,
                latitude: data.latitude,
                longitude: data.longitude,
                empNumber: data.empNumber,
            };

            await db.userDB.put({
                _id: data.Id,
                from: 'offline',
                lead: lead,
            });

            let lead_global = uuidv4();
            await db.globalsDB.put({
                _id: lead_global,
                change: {
                    version: version,
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'lead',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });

            // let masterId = uuidv4();
            // let master_status = {
            //     Id: masterId,
            //     itemId: data.Id,
            //     itemType: 'lead',
            //     dateCreated: DateTime.now().toISO(),
            //     status: 'new',
            // };

            // await db.userDB.put({
            //     _id: masterId,
            //     from: 'offline',
            //     status: master_status,
            // });

            // let status_global = uuidv4();
            // await db.globalsDB.put({
            //     _id: status_global,
            //     change: {
            //         version: version,
            //         entityId: masterId,
            //         userId: helper.getUserId(),
            //         entityType: 'status',
            //         dateModified: DateTime.now().toISO(),
            //         changeType: 'INSERT',
            //     },
            // });

            return {
                status: 200,
                data: {
                    result: {
                        success: true,
                        message: 'Lead inserted successfully offline.',
                    },
                    data: { Id: data.Id },
                },
            }
        }
        catch (error) {
            console.log(error);
            return {
                status: 500,
                data: {
                    result: {
                        success: false,
                        message: 'Unkown Error.',
                    },
                    error: {
                        code: 500,
                        message: error,
                    },
                },
            }
        }
    }
}

async function assignLead(data) {

    try {
        const res = await axios.post('/api/leads/assignlead', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function searchLeads(name) {
    const res = await axios.post(`/api/leads/search`, { name });
    return res.data;
}
