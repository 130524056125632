import axios from '../helpers/api';
import db from '@/services/pouchDb';
import helper from '../helpers/custom/index';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { version } from './../../package.json';
import Toastify from 'toastify-js';
import { userActivityLoggerService } from '@/services/userActivityLogger.service';
import { errorLogService } from '@/services/error_log.service';
import { tenantService } from '@/services/tenant.service';
import store from '@/state/store';
import { agentService } from './agent.service';

export const activitiesService = {
    getAllActivitiesForStatusWidget,
    getAllActivitiesForTypeWidget,
    getAllActivities,
    getMyWorkActivities,
    getActivityList,
    getActivity,
    getActivityTypes,
    getActivityStatuses,
    getActivityPlans,
    getActivityPlanDetails,
    insertActivity,
    insertActivityAssignment,
    insertActivityClient,
    insertActivityLead,
    insertActivityEntities,
    insertActivityActivityPlan,
    updateActivity,
    updateActivityStatus,
    updateActivityFiles,
    deleteActivities,
    deleteActivityPlan,
    bulkUpdateActivities,
    updateActivityAssignment,
    updateActivityActivityPlan,
    createLocalActivity,
    createLocalActivityPlan,
    getActivitiesWithNoActivityPlan,
    getActivityPlanActivities,
    getActivityAgents,
    getActivityAgentNames,
    getAgentActivitiesReport,
    fetchActivityStatuses,
    fetchActivityTypes,
    getTenantId,
};

/** Get the user's root tenant */
async function getTenantId() {
    try {
        let user = JSON.parse(localStorage.getItem('user') || {});
        return user?.tenants[0]?.Id || null;
    } catch (error) {
        console.log('Error fetching tenant Id', error);
    }

}
   

/** For Widgets */
async function getAllActivitiesForStatusWidget(startDate, endDate, status, page, size, sortField = 'startDate', sortOrder = 'DESC', search = '%%') {
    page = page || 1;
    size = size || 1000000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    status = status || [];

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/activities/for-status-widget?startDate=${startDate}&endDate=${endDate}&status=${status}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
            );
            if (res && res.data.result.success && res.data.data && !_.isEmpty(res.data.data)) {
                return res.data;
            } else {
                //an API error has occurred, do whatever....
                return res;
            }
        } catch (error) {
            return error;
        }
    } else {
        try {

            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const res = await db.userDB.allDocs({
                include_docs: true,
                include_attachments: true,
            });

            let activities = [];
    
            res.rows.forEach(row => {
                if (row.doc.activity) {
                    if (
                        ((DateTime.fromJSDate(new Date(row.doc.activity.startDate)) >=
                            DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                            DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                            DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss')) ||
                            ((DateTime.fromJSDate(new Date(row.doc.activity.endDate)) >=
                                DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                                DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                                DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss'))) &&
                            (row.doc.activity.status == status || status == 'ALL'))
                    ) {
                        activities.push(row.doc.activity);
                    }
                }
            });

            // TODO: filter by date range
            activities = activities.filter(activity => {
                return activity.statusId === status || status == 'ALL';
            });

            let pageNumber = page;
            const pageSize = size;

            const pages = Math.ceil(activities.length / size);
            if (pageNumber > pages) {
                pageNumber = pages;
            }
            const start = (pageNumber - 1) * pageSize;
            const end = start + pageSize;

            let total = activities.length;
            if (activities.length > 0) {
                activities = _.slice(activities, start, end);
            }
            console.log('offline edited activities', activities)

            let temp = {
                data: {
                    activities: activities,
                },
                meta: {
                    paging: {
                        pageNumber,
                        pageSize: size,
                        total: total,
                    },
                },
                result: {
                    success: true,
                    message: 'Activities retrieved successfully.',
                },
            };

            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}

async function getAllActivitiesForTypeWidget(startDate, endDate, status, page, size, sortField = 'startDate', sortOrder = 'DESC', search = '%%') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    status = status || [];

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/activities/for-type-widget?startDate=${startDate}&endDate=${endDate}&status=${status}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
            );
            if (res && res.data.result.success && res.data.data && !_.isEmpty(res.data.data)) {
                return res.data;
            } else {
                //an API error has occurred, do whatever....
                return res;
            }
        } catch (error) {
            return error;
        }
    } else {
        try {

            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const res = await db.userDB.allDocs({
                include_docs: true,
                include_attachments: true,
            });

            let activities = [];
            //console.log('offline documents', res.rows);
            // console.log(res.rows, startDate, endDate);
            res.rows.forEach(row => {
                if (row.doc.activity) {
                    if (
                        ((DateTime.fromJSDate(new Date(row.doc.activity.startDate)) >=
                            DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                            DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                            DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss')) ||
                            ((DateTime.fromJSDate(new Date(row.doc.activity.endDate)) >=
                                DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                                DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                                DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss'))) &&
                            (row.doc.activity.status == status || status == 'ALL'))
                    ) {
                        activities.push(row.doc.activity);
                    }
                }
            });

            // TODO: filter by date range
            activities = activities.filter(activity => {
                return activity.statusId === status || status == 'ALL';
            });

            let pageNumber = page;
            const pageSize = size;

            const pages = Math.ceil(activities.length / size);
            if (pageNumber > pages) {
                pageNumber = pages;
            }
            const start = (pageNumber - 1) * pageSize;
            const end = start + pageSize;

            let total = activities.length;
            if (activities.length > 0) {
                activities = _.slice(activities, start, end);
            }
            console.log('offline edited activities', activities)

            let temp = {
                data: {
                    activities: activities,
                },
                meta: {
                    paging: {
                        pageNumber,
                        pageSize: size,
                        total: total,
                    },
                },
                result: {
                    success: true,
                    message: 'Activities retrieved successfully.',
                },
            };

            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}
/** MAIN entry point for activities query */
async function getAllActivities(startDate, endDate, status=[], type=[], page, size, sortField = 'startDate', sortOrder = 'DESC', search = '%%') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    status = status || [];
    type = type || [];

  
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/activities?startDate=${startDate}&endDate=${endDate}&status=${status}&type=${type}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
            );
            if (res && res.data.result.success && res.data.data && !_.isEmpty(res.data.data)) {
                return res.data;
            } else {
                //an API error has occurred, do whatever....
                return res;
            }
        } catch (error) {
            return error;
        }
    } else {
        try {

            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const res = await db.userDB.allDocs({
                include_docs: true,
                include_attachments: true,
            });

            let activities = [];
            //console.log('offline documents', res.rows);
            // console.log(res.rows, startDate, endDate);
            res.rows.forEach(row => {
                if (row.doc.activity) {
                    if (
                        ((DateTime.fromJSDate(new Date(row.doc.activity.startDate)) >=
                            DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                            DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                            DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss')) ||
                            ((DateTime.fromJSDate(new Date(row.doc.activity.endDate)) >=
                                DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                                DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                                DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss'))) &&
                            (row.doc.activity.status == status || status == 'ALL'))
                    ) {
                        activities.push(row.doc.activity);
                    }
                }
            });

            // TODO: filter by date range
            activities = activities.filter(activity => {
                return activity.statusId === status || status == 'ALL';
            });

            let pageNumber = page;
            const pageSize = size;

            const pages = Math.ceil(activities.length / size);
            if (pageNumber > pages) {
                pageNumber = pages;
            }
            const start = (pageNumber - 1) * pageSize;
            const end = start + pageSize;

            let total = activities.length;
            if (activities.length > 0) {
                activities = _.slice(activities, start, end);
            }
            console.log('offline edited activities', activities)

            let temp = {
                data: {
                    activities: activities,
                },
                meta: {
                    paging: {
                        pageNumber,
                        pageSize: size,
                        total: total,
                    },
                },
                result: {
                    success: true,
                    message: 'Activities retrieved successfully.',
                },
            };

            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}

/** Used in the agent landing page ONLY, these are not cached */
async function getMyWorkActivities(startDate, endDate, status=[], page, size, sortField = 'startDate', sortOrder = 'DESC', search = '%%') {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 7 }).toISO();
    status = status || [];
 

    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/activities/agent-my-work-activities?startDate=${startDate}&endDate=${endDate}&status=${status}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
            );
            if (res && res.data.result.success && res.data.data && !_.isEmpty(res.data.data)) {
                return res.data;
            } else {
                //an API error has occurred, do whatever....
                return res;
            }
        } catch (error) {
            return error;
        }
    } else {
        try {

            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const res = await db.userDB.allDocs({
                include_docs: true,
                include_attachments: true,
            });

            let activities = [];
            //console.log('offline documents', res.rows);
            // console.log(res.rows, startDate, endDate);
            res.rows.forEach(row => {
                if (row.doc.activity) {
                    if (
                        ((DateTime.fromJSDate(new Date(row.doc.activity.startDate)) >=
                            DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                            DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                            DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss')) ||
                            ((DateTime.fromJSDate(new Date(row.doc.activity.endDate)) >=
                                DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss') &&
                                DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                                DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss'))) &&
                            (row.doc.activity.status == status || status == 'ALL'))
                    ) {
                        activities.push(row.doc.activity);
                    }
                }
            });

            // TODO: filter by date range
            activities = activities.filter(activity => {
                return activity.statusId === status || status == 'ALL';
            });

            let pageNumber = page;
            const pageSize = size;

            const pages = Math.ceil(activities.length / size);
            if (pageNumber > pages) {
                pageNumber = pages;
            }
            const start = (pageNumber - 1) * pageSize;
            const end = start + pageSize;

            let total = activities.length;
            if (activities.length > 0) {
                activities = _.slice(activities, start, end);
            }
            console.log('offline edited activities', activities)

            let temp = {
                data: {
                    activities: activities,
                },
                meta: {
                    paging: {
                        pageNumber,
                        pageSize: size,
                        total: total,
                    },
                },
                result: {
                    success: true,
                    message: 'Activities retrieved successfully.',
                },
            };

            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}

/** MAIN entry point for agent activities query */
async function getAgentActivitiesReport(
    startDate,
    endDate,
    status,
    page,
    size,
    sortField = 'statusDateCreated',
    sortOrder = 'DESC',
    search,
) {
    page = page || 1;
    size = size || 1000000;
    sortField = sortField || 'statusDateCreated';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 30 }).toISO();
    status = status || 'ALL';
    search = search || '%%';
    if (navigator.onLine) {
        try {
       
            const res = await axios.get(
                `/api/activities/agentActivityStatusReport?startDate=${startDate}&endDate=${endDate}&status=${status}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
            );

            if (res && res.data.result.success && res.data.data && !_.isEmpty(res.data.data)) {
                return res;
            } else {
                //an API error has occurred, do whatever....
                //console.log('Agent Report',res);
                return res;
            }
        } catch (error) {
            // console.log('Agent Report Error',error);
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let activities = [];
            res.rows.forEach(row => {
                if (row.doc.activity) {
                    if (
                        (DateTime.fromJSDate(new Date(row.doc.activity.startDate)) >=
                            DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss.SSS') &&
                            DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                            DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss.SSS')) ||
                        (DateTime.fromJSDate(new Date(row.doc.activity.endDate)) >=
                            DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss.SSS') &&
                            DateTime.fromJSDate(new Date(row.doc.activity.endDate)) <=
                            DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss.SSS') &&
                            (row.doc.activity.status == status || status == 'ALL'))
                    ) {
                        activities.push(row.doc.activity);
                    }
                }
            });
            let temp = {
                data: { activities: activities },
                meta: {
                    paging: {
                        pageNumber: page,
                        pageSize: size,
                        total: activities.length,
                    },
                },
                result: {
                    success: true,
                    message: 'Agent Activities retrieved successfully.',
                },
            };

            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}

/** For offline, it should is only returning the currently assigned agent, in order to get all agent names
 * we will have to do the different setup when agent namess are synched for each user
 * ToDo: Thus, need to decide if want to apply this for managers off-line as well...
 */
async function getActivityAgentNames(activityId) {
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/activities/activityagents/${activityId}`);
            if (res && res.data.result.success && res.data.data && !_.isEmpty(res.data.data)) {
                return res.data;
            } else {
                //an API error has occurred, do whatever....
                return res;
            }
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                await db.refreshUserDb();
            }
            const res = await db.userDB.get(activityId);

            let agent = res.activity.agentName;
            if (agent == '') console.log('agents assigned not in the pouch');
            return {
                data: { agents: [agent] },
                result: {
                    success: true,
                    message: 'Agent names retrieved successfully',
                },
            };
        } catch (error) {
            console.log('Get activity agent names error', error);
        }
    }
}

async function getActivityPlanActivities(activityPlanId, fullDetails = false) {
    if (navigator.onLine) {
        const res = await axios.get(
            `/api/activities/activity-plan-activities/${activityPlanId}?detailsLevel=${fullDetails ? 'FULL' : 'BASIC'}`,
        );
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            // just return the activities that are contained in the list

            let activities = [];
            let activityPlan = null;
            res.rows.forEach(row => {
                if (row.doc.activityPlan && row.doc.activityPlan.Id === activityPlanId) {
                    activityPlan = row.doc.activityPlan;
                }
            });
            if (activityPlan) {
                let activityArray = [];
                if (!_.isArray(activityPlan.activityIds)) {
                    activityArray = _.split(activityPlan.activityIds, ',') || [activityPlan.activityIds];
                }

                res.rows.forEach(row => {
                    if (row.doc.activity && _.findIndex(activityArray, row.doc.activity.Id) > -1) {
                        activities.push(row.doc.activity);
                    }
                });
            }
            let temp = {
                data: { activities: activities },
                // meta: {
                //     paging: { pageNumber: page, pageSize: size, total: activities.length }
                // },
                result: {
                    success: true,
                    message: 'Activities retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}
async function getActivitiesWithNoActivityPlan(startDate, endDate) {
    
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 30 }).toISO();


    if (navigator.onLine) {
        const res = await axios.get(`/api/activities/no-activity-plan?startDate=${startDate}&endDate=${endDate}`);
        return res.data;
    } else {
        try {
            // const res = await db.userDB.allDocs({
            //     include_docs: true,
            //     attachments: true
            // })
            // let activityplanless = [];
            // let activities = [];
            // res.rows.forEach(row => {
            //     if (row.doc.activity) {
            //         activities.push(row.doc.activity);
            //     }
            // })
            // res.rows.forEach(row => {
            //     if (row.doc.activityplan) {
            //         activities.forEach(activity =>{
            //             if(row.doc.activityplan)
            //         })
            //     }
            // })
            // console.log(activities);
            // let temp = {
            //     data: { activities: activities },
            //     meta: {
            //         paging: { pageNumber: page, pageSize: size, total: activityplanless.length }
            //     },
            //     result: {
            //         "success": true,
            //         "message": "Activities retrieved successfully."
            //     }
            // }
            // console.log("offline activities: ",temp);
            // return temp;
        } catch (error) {
            console.log(error);
        }
    }
}

async function getActivity(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/activities/${id}`);
        return res.data;
    } else {
        try {

            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const res = await db.userDB.allDocs({
                include_docs: true
            });
            let temp = {};
            res.rows.forEach(row => {
                if (row.doc.activity != undefined && row.doc.activity.Id == id) {
                    let activity = row.doc.activity;
                    try {
                        let assignedUsers = activity.assignedUsers.map(user => { return { Id: user.Id, firstName: 'Unknown', lastName: 'Unknown' } });
                        assignedUsers.forEach(user => {
                            res.rows.forEach(row => {
                                if (row.doc.user && row.doc.user.Id == user.Id) {
                                    user.firstName = row.doc.user.firstName;
                                    user.lastName = row.doc.user.lastName;
                                }
                            });
                        });
                        activity.assignedUsers = assignedUsers;
                    }
                    catch {
                        let assignedUsers = [{ Id: activity.assignedUsers, firstName: 'Unknown', lastName: 'Unknown' }];
                        assignedUsers.forEach(user => {
                            res.rows.forEach(row => {
                                if (row.doc.user && row.doc.user.Id == user.Id) {
                                    user.firstName = row.doc.user.firstName;
                                    user.lastName = row.doc.user.lastName;
                                }
                            });
                        });
                        activity.assignedUsers = assignedUsers;
                    }
                    temp = {
                        data: { activity: row.doc.activity },
                        result: {
                            success: true,
                            message: 'Activity retrieved successfully.',
                        },
                    };
                }
            });

            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}
async function getActivityList(idList) {
    if (navigator.onLine) {
        if (idList == '') {
            idList = '00000000-0000-0000-0000-000000000000';
        }
        const res = await axios(`/api/activities/list/${idList}`);

        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            // just return the activities that are contained in the list
            let activityArray = [];
            if (!_.isArray(idList)) {
                activityArray = _.split(idList, ',');
            } else {
                activityArray = idList;
            }

            let activities = [];
            res.rows.forEach(row => {
                if (row.doc.activity && _.findIndex(activityArray, row.doc.activity.Id) > -1) {
                    activities.push(row.doc.activity);
                }
            });

            //console.log('offline activities', activities);
            let temp = {
                data: { activities: activities },
                meta: {
                    paging: {
                        pageNumber: 1,
                        pageSize: 1,
                        total: activities.length,
                    },
                },
                result: {
                    success: true,
                    message: 'Activities retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}

async function getActivityTypes() {
    if (navigator.onLine) {
        const res = await axios('/api/activities/types');
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const res = await db.globalsDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let types = [];
            res.rows.forEach(row => {
                if (row.doc.activityType != undefined) types.push(row.doc.activityType);
            });
            //console.log('offline types',types);
            return {
                data: { activityTypes: types },
                result: {
                    success: true,
                    message: 'Activity Types retrieved successfully.',
                },
            };
        } catch (error) {
            console.log(error);
        }
    }
}

/** fetch activity type for a particular tenant (and it's decendants if a group)
 *  label is the name of the type (will include short code if on a group)
 *  value is the Id of the type
 *  text is the name of the type 
*/
async function fetchActivityTypes(tenantId) {
    try {
        
        let activityTypes = [];
        /** If not tenant ID get User's base tenant */

        if (_.isEmpty(tenantId) || _.isNull(tenantId) || _.isUndefined(tenantId)) {   
            tenantId = await getTenantId();         
            if (_.isEmpty(tenantId) || _.isNull(tenantId) || _.isUndefined(tenantId)) {
                return activityTypes;            
           }
        }

        const res = await activitiesService.getActivityTypes();
        let tenantList =  store.getters['auth/getCurrentUser'].tenants;

        
        if (_.isEmpty(tenantList) || _.isNull(tenantList) || _.isUndefined(tenantList)) {
            const { data } = await tenantService.getAllTenantDescendantsByUserId();                      
            tenantList =  data.tenants;
        }
        
        let tenant = _.find(tenantList,  tenant => tenant.Id === tenantId);
        
        // limit the statuses to only those of the selected tenant's company
        let { result, data } = await tenantService.getTenantCompany(tenantId);
        
        

        if (result.success && !_.isNull(data.tenant) && !_.isNull(tenant.parentId)) {
                  
      
            // loop through the activityTypes and only return the statuses that map to a valid companyId
            for (let i = 0; i < res.data.activityTypes.length; i++) {
                if (((res.data.activityTypes[i].tenantId === data.tenant.Id) || _.isNull(tenant.parentId)) && res.data.activityTypes[i].status ==1) {
                    activityTypes.push({
                        value: res.data.activityTypes[i].Id,
                        label: res.data.activityTypes[i].name,
                        text: res.data.activityTypes[i].name,                        
                    });                    
                }
            }
        } else {
            
            // result == false, so we have no company, 
            // so just set the status for the selected tenant (the ultimate parent)?
            // if the ultimate parent has no status, then we need to add all the statuses for the tenant decendant companies
            
            for (let i = 0; i < res.data.activityTypes.length; i++) {
                
                if (res.data.activityTypes[i].status == 1)
                    {
            
                        // get the tenant details for the status from the user's tenant list
                         let statusTenantDetail = _.find(tenantList,  tenant => tenant.Id === res.data.activityTypes[i].tenantId);
                         let shortCode = ' ('+ statusTenantDetail.shortCode +')' || '';

                            activityTypes.push({
                                value: res.data.activityTypes[i].Id,
                                label: res.data.activityTypes[i].name + shortCode,
                                text: res.data.activityTypes[i].name,                      
                            
                    });
                }
            }
        }
                activityTypes = _.sortBy(activityTypes, ['label'], ['asc']);
         return activityTypes;
            
    } catch (error) {
        console.log('There was a problem fetching activity types', error);
    }
};
/** fetch activity status for a particular tenant (and it's decendants if a group)
 *  label is the name of the status (will include short code if on a group)
 *  value is the Id of the status
 *  text is the name of the status
 *  default is the default status
*/
async function fetchActivityStatuses(tenantId) {
    try {
        
        let activityStatuses = [];
                /** If not tenant ID get User's base tenant */
        if (_.isEmpty(tenantId) || _.isNull(tenantId) || _.isUndefined(tenantId)) {   
            tenantId = await getTenantId();         
            if (_.isEmpty(tenantId) || _.isNull(tenantId) || _.isUndefined(tenantId)) {
                return activityStatuses;            
           }
        }

        const res = await activitiesService.getActivityStatuses();

        let isEmpty = res.data.activityStatus || [];
     
       
        if (_.isEmpty(isEmpty) || _.isNull(isEmpty) || _.isUndefined(isEmpty)) {
            return isEmpty;
        }
       
        let tenantList =  store.getters['auth/getCurrentUser'].tenants;
       
        if (_.isEmpty(tenantList) || _.isNull(tenantList) || _.isUndefined(tenantList)) {
            const { data } = await tenantService.getAllTenantDescendantsByUserId();                      
            tenantList =  data.tenants;
        }
        
        let tenant = _.find(tenantList,  tenant => tenant.Id === tenantId);

        // limit the statuses to only those of the selected tenant's company
        let { result, data } = await tenantService.getTenantCompany(tenantId);
        
        if (result.success && !_.isNull(data.tenant) && !_.isNull(tenant.parentId)) {
                 
      
            // loop through the activityStatuses and only return the statuses that map to a valid companyId
            for (let i = 0; i < isEmpty.length; i++) {
                if (isEmpty[i].tenantId === data.tenant.Id || _.isNull(tenant.parentId)) {
  
                    activityStatuses.push({
                        value: isEmpty[i].Id,
                        label: isEmpty[i].name,
                        text: isEmpty[i].name,
                        default: isEmpty[i].default,
                    });                    
                }
            }
        } else {
       
            // result == false, so we have no company, 
            // so just set the status for the selected tenant (the ultimate parent)?
            // if the ultimate parent has no status, then we need to add all the statuses for the tenant decendant companies
            for (let i = 0; i < isEmpty.length; i++) {
                
                let statusTenantDetail = _.find(tenantList,  tenant => tenant.Id === isEmpty[i].tenantId);
                if (!_.isNull(statusTenantDetail) && !_.isUndefined(statusTenantDetail)) {
                    let shortCode = ' ('+ statusTenantDetail.shortCode +')' || '';    
        
                    if (res.data.activityStatus[i].status === 1) {
                        activityStatuses.push({
                            value: isEmpty[i].Id,
                            label: isEmpty[i].name + shortCode,
                            text: isEmpty[i].name,
                            default: isEmpty[i].default,
                        });
                    }
                }                    
            }
        }
            activityStatuses = _.sortBy(activityStatuses, ['label'], ['asc']);
      
         return activityStatuses;
            
    } catch (error) {
        console.log('There was a problem fetching activity statuses', error);
    }
};
async function getActivityStatuses() {
    if (navigator.onLine) {
        const res = await axios('/api/activities/statuses');
        //add to cache

        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.globalsDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let statuses = [];
            res.rows.forEach(row => {
                if (row.doc.activityStatus != undefined) statuses.push(row.doc.activityStatus);
            });
            //console.log('offline statuses',statuses);
            return {
                data: { activityStatus: statuses },
                result: {
                    success: true,
                    message: 'Activity Statuses retrieved successfully.',
                },
            };
        } catch (error) {
            console.log(error);
        }
    }
}

async function getActivityPlans(startDate, endDate, page, size, sortField = 'startDate', sortOrder = 'DESC', search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'startDate';
    sortOrder = sortOrder || 'DESC';
    startDate = startDate || DateTime.now().plus({ days: -1 }).toISO();
    endDate = endDate || DateTime.now().plus({ days: 30 }).toISO();
    search = search || '%%';

    if (navigator.onLine) {
        const res = await axios.get(
            `/api/activities/activity-plan?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
        );
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let activityPlans = [];
            res.rows.forEach(row => {
                if (row.doc.activityPlan) activityPlans.push(row.doc.activityPlan);
            });
            let temp = {
                data: { activityPlan: activityPlans },
                result: {
                    success: true,
                    message: 'Activity plans retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            console.log(error);
        }
    }
}

async function getActivityPlanDetails(id) {
    if (navigator.onLine) {
        const res = await axios(`/api/activities/activity-plan/${id}`);
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let activityPlans = [];
            res.rows.forEach(row => {
                if (row.doc.activityPlan && row.doc.activityPlan.Id == id) {
                    activityPlans.push(row.doc.activityPlan);
                }
            });
            let temp = {
                data: { activityPlan: activityPlans },
                result: {
                    success: true,
                    message: 'Activity plan retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            //console.log(error);
        }
    }
}
// This does not exist as a rest endpoint or in data access layer
// async function getActivityPlanDetailsByAssignedUserId(id) {
//     try {
//         const res = await axios(`/api/activities/activity-plan/by-user/${id}`);
//         return res.data;
//     } catch (error) {
//         return error;
//     }
// }

// ToDo: Not used?
async function getActivityAgents(id) {
    const res = await axios(`/api/activities/agents/${id}`);
    return res.data;
}

async function insertActivity(data, userId) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        if (data.location == '' || data.location.toLowerCase() === 'unknown' ) {
            const locres = await axios.get(`/api/tenants/location/${userId}`);
            data.location = locres.data.data[0].location;
            data.lat = locres.data.data[0].lat;
            data.lng = locres.data.data[0].lng;
        }
        const res = await axios.post('/api/activities', data);
        const newFiles = data.newFiles || [];

              // remove and add files seperately
              if (newFiles.length > 0) {
                newFiles.forEach(async (file) =>                 
                {
                    
                    let insertData = {
                        Id: uuidv4(),
                        action: 2,
                        performedByUserId: userId,
                        dateCreated: DateTime.now().toISO(),
                    };
                
                    await agentService.insertAgentAction(insertData);
                });
            }      

        return res.data;
    } else {
        let user = JSON.parse(localStorage.getItem('user'));
        let activity = {};
        if (data.location == '') {
            let tenants = user.tenants;
            // TODO: This needs to be changed if the user has multiple tenants attached
            if (tenants.length > 1 && tenants.filter(tenant => tenant.tenant_type == '4').length > 0) {
                tenants.forEach(tenant => {
                    if (tenant.location && tenant.location != '' && (tenant.tenant_type == '4' || tenant.tenant_type == 'BRANCH')) {
                        data['location'] = tenant.location;
                        data['lat'] = tenant.lat;
                        data['lng'] = tenant.lng;
                    }
                });
            } else {
                data['location'] = tenants[0].location;
                data['lat'] = tenants[0].lat;
                data['lng'] = tenants[0].lng;
            }
            // const locres = await axios.get(`/api/tenants/location/${userId}`);
            // data.location = locres.data.data[0].location;
            // data.lat = locres.data.data[0].lat;
            // data.lng = locres.data.data[0].lng;
        }
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            activity = {
                Id: data.Id,
                parentId: data.parentId || null,
                name: data.name || '',
                description: data.description || '',
                dateCreated: DateTime.now().toISO(),
                activityTypeId: data.activityTypeId || null,
                statusId: data.status,
                statusText: data.statusText,
                location: data.location,
                endDate: data.endDate || '',
                lat: data.lat || '0',
                lng: data.lng || '0',
                startDate: data.startDate || '',
                createdUserId: userId,
                assignedUsers: data.assignedUsers ? data.assignedUsers : [],
                client: data.client ? data.client : [],
                agentName: '',
                userName: '',
                status: 'PENDING',


            };

            // fetch agent name and status so can display offline until sync
            const { rows } = await db.userDB.allDocs({
                include_docs: true
            });

            // deal with offline display issues            
            rows.forEach(row => {
                if (row.doc.user && row.doc.user.Id in activity.assignedUsers) {
                    activity.agentName += row.doc.user.firstName + ' ' + row.doc.user.lastName + ', ';
                }
                if (row.doc.user && row.doc.user.Id == activity.createdUserId) {
                    activity.username = row.doc.user.firstName + ' ' + row.doc.user.lastName;
                }
            });

            console.log('INSERT ACTIVITY OFFLINE', activity);

            await db.userDB.put({
                _id: data.Id,
                from: 'offline',
                activity: activity,
            });

            let activity_global = uuidv4();
            await db.globalsDB.put({
                _id: activity_global,
                change: {
                    version: version,
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'activity',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });

            return { data: { activity: { Id: data.Id } } };
        } catch (error) {
            console.log('ERROR SAVING ACTIVITY');
            console.log(error);
        }
    }
}

async function insertActivityAssignment(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();

    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/activities/assignment', data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        // unnecessary as dealt with by the activity insert
        // try {
        //     data.users.map(async item => {
        //         await db.userDB.put({
        //             _id: uuidv4(),
        //             from: 'offline',
        //             activityAssignment: item,
        //         });
        //         let changeId = uuidv4();
        //         await db.globalsDB.put({
        //             _id: changeId,
        //             change: {
        //                 version: version,
        //                 entityId: data.Id,
        //                 userId: helper.getUserId(),
        //                 entityType: 'activityAssignment',
        //                 dateModified: DateTime.now().toISO(),
        //                 changeType: 'INSERT',
        //             },
        //         });
        //     });

        //     // return data.Id;
        // } catch (error) {
        //     console.log(error);
        // }
    }
}

async function insertActivityClient(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/activities/client', data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            // data.client.map(async item => {
            //     await db.userDB.put({
            //         _id: uuidv4(),
            //         activityClient: item,
            //     });
            //     let changeId = uuidv4();
            //     await db.globalsDB.put({
            //         _id: changeId,
            //         change: {
            //             version: version,
            //             entityId: data.Id,
            //             userId: helper.getUserId(),
            //             entityType: 'activityClient',
            //             dateModified: DateTime.now().toISO(),
            //             changeType: 'INSERT',
            //         },
            //     });
            // });
            return;
            // return data.Id;
        } catch (error) {
            console.log(error);
        }
    }
}

async function insertActivityLead(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/activities/lead', data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            // data.client.map(async item => {
            //     await db.userDB.put({
            //         _id: uuidv4(),
            //         activityClient: item,
            //     });
            //     let changeId = uuidv4();
            //     await db.globalsDB.put({
            //         _id: changeId,
            //         change: {
            //             version: version,
            //             entityId: data.Id,
            //             userId: helper.getUserId(),
            //             entityType: 'activityClient',
            //             dateModified: DateTime.now().toISO(),
            //             changeType: 'INSERT',
            //         },
            //     });
            // });
            return;
            // return data.Id;
        } catch (error) {
            console.log(error);
        }
    }
}

async function insertActivityEntities(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/activities/entities', data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            await db.userDB.put({
                _id: data.Id,
                from: 'offline',
                activity_entity: data,
            });

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    version: version,
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'activityEntity',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });

            return;
        } catch (error) {
            console.log(error);
        }
    }
}

async function insertActivityActivityPlan(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/activities/activity-plan', data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            await db.userDB.put({
                _id: data.Id,
                from: 'offline',
                activityplan: data,
            });

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    version: version,
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'activityPlan',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });

            let masterId = uuidv4();
            let master_status = {
                Id: masterId,
                itemId: data.Id,
                itemType: 'activityPlan',
                dateCreated: DateTime.now().toISO(),
                status: data.status || 'PENDING',
            };

            await db.userDB.put({
                _id: masterId,
                from: 'offline',
                status: master_status,
            });

            let status_global = uuidv4();
            await db.globalsDB.put({
                _id: status_global,
                change: {
                    version: version,
                    entityId: masterId,
                    userId: helper.getUserId(),
                    entityType: 'status',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });

            return;
        } catch (error) {
            console.log(error);
        }
    }
}

async function updateActivity(id, data) {
    if (navigator.onLine) {
    
        const res = await axios.put(`/api/activities/${id}`, data);
        const newFiles = data.newFiles || [];
        const removedFiles = data.removedFiles || [];
        const user = JSON.parse(localStorage.getItem('user'));
    

              // remove and add files seperately
              if (newFiles.length > 0) {
                newFiles.forEach(async (file) =>                 
                {
                    
                    let insertData = {
                        Id: uuidv4(),
                        action: 2,
                        performedByUserId: user.Id,
                        dateCreated: DateTime.now().toISO(),
                    };
                  
                    await agentService.insertAgentAction(insertData);
                });
            }
            if (removedFiles.length > 0) {
                removedFiles.forEach(async (file) => {
                    let insertData = {
                        Id: uuidv4(),
                        action: 3,
                        performedByUserId: user.Id,
                        dateCreated: DateTime.now().toISO(),
                    }; 
                   
                    await agentService.insertAgentAction(insertData);                
                });
            }
     
        return res.data;
    } else {
        try {
            console.log('update activity offline', data);
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }

            const doc = await db.userDB.get(id);
            data.Id = id;
            data.statusId = data.status;
            data.status = data.statusText;
            data.branch = doc.activity.branch;
            data.region = doc.activity.region;
            data.clients = doc.activity.clients;
            data.dateModified = DateTime.now().toISO();
            data.createdBy = doc.activity.createdBy;
            data.userName = doc.activity.userName;
            data.createdUserId = doc.activity.createdUserId;
            data.activityDateCreated = doc.activity.activityDateCreated;
            data.activityPlans = doc.activity.activityPlans;
            data.activityPlanNames = doc.activity.activityPlanNames;
            data.typeDesc = doc.activity.typeDesc;
            data.leads = doc.activity.leads;
            data.activityAssignmentId = doc.activity.activityAssignmentId;
            data.agentName = data.assignedUsers.map(user => user.label).join(', ');
            await db.userDB.put(
                {
                    _id: id,
                    _rev: doc._rev,
                    from: 'offline',
                    activity: data,
                },
                { force: true },
            );

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    version: version,
                    entityId: id,
                    userId: helper.getUserId(),
                    entityType: 'activity',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'UPDATE',
                },
            });
            let types = [];
            doc.rows.forEach(row => {
                if (row.doc.activityType != undefined) types.push(row.doc.activityType);
            });

            return {
                data: { activity: doc, },
                result: {
                    success: true,
                    message: 'Activity Updated Successfully.',
                },
            };
        } catch (error) {
            //console.log("update Activity error ", error);
        }
    }
}
/** Update (Add or Remove) files on an existing activity and log to agent actions */
async function updateActivityFiles(assignedUserId, updatingUserId, activityId, geotag, newFiles = [], removedFiles = []) {
    let trackingresult = {success: false, message: 'Updated Activity files', data: {activity: {Id: activityId}}};     

 
    if (navigator.onLine) {

        if (geotag) {
            
            try {
                let track = await userActivityLoggerService.createActivityAssignmentTracking(
                    uuidv4(),
                    updatingUserId,
                    activityId,
                    'activityAssignment',
                );

                if (!track.success) {
                    errorLogService.insertErrorLog(
                        uuidv4(),
                        updatingUserId,
                        activityId,
                        'activityAssignment',
                        JSON.stringify(track.error),
                        DateTime.now().toISO(),
                    );
    
                    Toastify({
                        text: 'Unable to log GPS Position! Please activate your GPS.',
                        className: 'info',
                        style: {
                            background: 'linear-gradient(to right, #00b09b, #96c93d)',
                        },
                    }).showToast();
                }
                trackingresult = track.data;
            } catch (error) {
                console.log('update Activity Files error ', error);
                trackingresult = error;
            }
        }
        
       
        const res = await axios.put(`/api/activities/update-assignment-files`, {       
            activityId: activityId,        
            newFiles: newFiles,          
            removedFiles: removedFiles,
        });

        if (newFiles.length > 0) {
            newFiles.forEach(async (file) =>                 
            {
                let insertData = {
                    Id: uuidv4(),
                    action: 2,
                    performedByUserId: updatingUserId,
                    dateCreated: DateTime.now().toISO(),
                };
                await agentService.insertAgentAction(insertData);
            });
        }
        if (removedFiles.length > 0) {
            removedFiles.forEach(async (file) => {
                let insertData = {
                    Id: uuidv4(),
                    action: 3,
                    performedByUserId: updatingUserId,
                    dateCreated: DateTime.now().toISO(),
                }; 
                await agentService.insertAgentAction(insertData);                
            });
        }

        return trackingresult;
        
    } 
    else
    {
        return {
            success: false,
            message: 'Unable to update activity files offline',
            data: {
                activity: {
                    Id: activityId,
                    newFiles: newFiles, 
                    removedFiles: removedFiles,
                },
            },
        };
    }
}

/** Updating UserId is the ID of the User that changed the status, where as assignedUserId, is the assigned agents Id */
async function updateActivityStatus(assignedUserId, updatingUserId, activityId, statusId, statusText, geotag) {
   
     // Log errors and data connected with users if tracking fails
     let trackingresult = null     

    if (navigator.onLine) {

        if (geotag) {
            
            try {
                let track = await userActivityLoggerService.createActivityAssignmentTracking(
                    uuidv4(),
                    updatingUserId,
                    activityId,
                    'activityAssignment',
                );

                if (!track.success) {
                    errorLogService.insertErrorLog(
                        uuidv4(),
                        updatingUserId,
                        activityId,
                        'activityAssignment',
                        JSON.stringify(track.error),
                        DateTime.now().toISO(),
                    );
    
                    Toastify({
                        text: 'Unable to log GPS Position! Please activate your GPS.',
                        className: 'info',
                        style: {
                            background: 'linear-gradient(to right, #00b09b, #96c93d)',
                        },
                    }).showToast();
                }
                trackingresult = track.data;
            } catch (error) {
                console.log('update ActivityStatus error ', error);
                trackingresult = error;
            }
        }        

        const res = await axios.put(`/api/activities/updateAssignmentStatus`, {
            userId: assignedUserId,
            updatingUserId: updatingUserId,
            activityId: activityId,
            statusId: statusId,
            statusText: statusText,          
        });


        return trackingresult;
        
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            // console.log("userId", userId, "activityId", activityId)
            const docs = await db.userDB.allDocs({ include_docs: true });
            let doc = null;

            // update both status and status text, as status is used in the UI
            // the api returns status, as the UI uses status
            // but the update service looks for statusText
            docs.rows.forEach(async item => {
                if (item.doc.activity && item.doc.activity.assignedUsers == assignedUserId && item.doc.activity.Id == activityId) {
                    doc = item.doc;
                    doc.activity.updatingUserId = updatingUserId;
                    doc.activity.statusId = statusId;
                    doc.activity.status = statusText;
                    doc.activity.statusText = statusText;                  
                    
                    // This is a special case because of the Assigned User POSSIBLY being different from the user doing the Update!
                    let track = await userActivityLoggerService.createTracking(
                        uuidv4(),
                        doc.activity.Id,
                        'activityAssignment',
                        assignedUserId,
                    );
                    if (track.success) {
                        doc.track = track;
                    } else {
                        doc.track = { success: false };
                    }
                    doc.from = 'offline';
                    await db.userDB.put(doc, { force: true });
                }
            });
            if (!_.isNull(doc)) {
                if (!db.userDB || _.isEmpty(db.userDB)) {
                    db.refreshUserDb();
                }
                let changeId = uuidv4();
                //console.log('DOC NOT NULL', doc);

                await db.globalsDB.put({
                    _id: changeId,
                    change: {
                        version: version,
                        entityId: activityId,
                        userId: helper.getUserId(),
                        entityType: 'activityAssignment',
                        dateModified: DateTime.now().toISO(),
                        changeType: 'UPDATE',
                    },
                });
                //console.log('offline status update', doc)
                return {
                    success: true,
                    data: {
                        activity: doc,
                    },
                };
            } else {
                return {
                    success: false,
                    message: 'Activity not found',
                    data: {
                        activity: {},
                    },
                };
            }
        } catch (error) {
            console.log('update ActivityStatus error ', error);
        }
    }
}

async function updateActivityActivityPlan(id, data) {
    const res = await axios.put(`/api/activities/activity-plan/${id}`, data);
    return res.data;
}

async function bulkUpdateActivities(data) {
    const res = await axios.post(`/api/activities/bulk-update`, data);
    return res.data;
}

async function deleteActivities(data) {
    if (navigator.onLine) {
        const res = await axios.post(`/api/activities/delete`, { id: data });
        return res.data;
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.refreshUserDb();
            }
            const doc = await db.userDB.get(data);
            await db.userDB.remove(doc);
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    version: version,
                    entityId: data,
                    userId: helper.getUserId(),
                    entityType: 'activity',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'DELETE',
                },
            });
        } catch (error) {
            //console.log("delete Activity error ", error);
        }
    }
}

async function updateActivityAssignment(id, data) {
    try {
        const res = await axios.put('/api/activities/assignment', data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function createLocalActivity(modelId, versionId, data) {
    return db.post({
        model: { modelId: modelId, versiondId: versionId },
        data: data,
    });
}

async function createLocalActivityPlan(modelId, versionId, data) {
    return db.post({
        model: { modelId: modelId, versiondId: versionId },
        data: data,
    });
}

async function deleteActivityPlan(activityPlans, deleteActivities) {
    try {
        const res = await axios.delete('/api/activities/activity-plan/delete', {
            data: {
                activityPlans: activityPlans,
                deleteActivities: deleteActivities,
            },
        });
        return res.data;
    } catch (error) {
        return error;
    }
}
