<template>
    <a href class="text-reset notification-item">
        <div class="media">
            <img
                v-if="showImage"
                :src="imageUrl"
                class="me-3 rounded-circle avatar-xs"
                alt="user-pic"
            />
            <span
                v-if="!showImage"
                class="me-3 rounded-circle bg-primary font-size-16 avatar-xs event-type-text"
            >
                {{ capsEventType() }}
            </span>
            <div class="media-body">
                <h6 class="mt-0 mb-1">
                    {{ Header }}
                </h6>
                <div class="font-size-12">
                    <p class="mb-1">
                        {{ eventText }}
                    </p>
                    <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i>
                        {{ $filters.formateTimePast(new Date(eventTime)) }}
                    </p>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: 'notification-item',
    props: {
        showImage: Boolean,
        imageUrl: String,
        Header: String,
        eventText: String,
        eventTime: Date,
        eventType: String,
    },
    computed: {
        iconUrl() {
            if (this.imageUrl) {
                let str = '@' + this.imageUrl;
                return str;
            }
            return '';
            // The path could be '../assets/img.png', etc., which depends on where your vue file is
        },
    },
    methods: {
        capsEventType() {
            if (this.eventType) {
                if (this.eventType.length >= 2) {
                    return this.eventType.substring(0, 2).toUpperCase();
                }
            }
            return 'BC';
        },
    },
};
</script>
