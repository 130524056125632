import { categoriesService } from '@/services/reportsCategories.service';

export const state = {
    type: null,
    message: null,
    reportList: [],
    currentTenant: null
};

export const mutations = {
    reportComplete(state, message) {
        state.type = message.success ? 'success' : 'error';
        state.message = message;
    }, 
    clear(state) {
        state.type = null;
        state.message = null;
    } ,
    setReportList(state, reportList) {
        state.reportList = reportList;
    },
    setCurrentTenant(state, currentTenant) {
        state.currentTenant = currentTenant;
    }
};

export const actions = {
    reportComplete({ commit }, message) {
        commit('reportComplete', message);
    }, 
    clear({ commit }) {
        commit('clear');
    } ,
    async getReportsList({ state,commit },args) {         
        if(args[1]==false && state.currentTenant===args[0] && state.reportList.length>0){
            return;
        }
        const response = await categoriesService.getCategoriesByUser(args[2]);
        
        if(response.data && response.data.reportCategories){
            commit('setReportList', response.data.reportCategories);
            commit('setCurrentTenant', args[0]);
        }
    }
};
