import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import helper from '../helpers/custom';
import { DateTime } from 'luxon';

export const categoriesService = {
    getCategories,
    getCategoriesById,
    getCategoriesByUser,
    createCategories,
    updateCategories,
    deleteCategories,
};

async function getCategories(page, size, sortField, sortOrder, search) {
    page = page || 1;
    size = size || 10000;
    sortField = sortField || 'dateCreated';
    sortOrder = sortOrder || 'DESC';
    search = search || '%%';
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/report-categories?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search}`,
            );
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        const getEntityDescendants = (data, entityId) => data.filter(entity => entity.parentCategoryId === entityId);
        const sortEntity = entities => {
            // copy entities array to avoid
            let data = _.cloneDeep(entities);
            // variable to hold end results
            let results = [];
            const childrenIds = [];

            data.forEach(entity => {
                // attach children
                entity.children = getEntityDescendants(data, entity.Id);
                entity.key = entity.Id;
                entity.label = entity.name;
                // get children ids
                entity.children.forEach(item => {
                    childrenIds.push(item.Id);
                });
                results.push(entity);
            });

            // exclude entity children from main results
            childrenIds.forEach(id => {
                results = results.filter(entity => entity.Id !== id);
            });

            return results;
        };

        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const { rows } = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            const reportCategories = [];
            rows.forEach(row => {
                if (row.doc.reportCategory) {
                    reportCategories.push(row.doc.reportCategory);
                }
            });
            return {
                result: {
                    success: true,
                    message: 'report categories retrieved successfully',
                },
                data: {
                    reportCategories,
                    sorted: sortEntity(reportCategories),
                },
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to retrieve report categories',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function getCategoriesById(id) {
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/report-categories/${id}`);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let response = {};
            res.rows.forEach(row => {
                if (row.doc.reportCategory !== undefined && row.doc.reportreportCategory.Id === id) {
                    response = {
                        data: { reportCategory: row.doc.reportCategory },
                        result: {
                            success: true,
                            message: 'Report category retrieved successfully.',
                        },
                    };
                }
            });
            return response;
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to retrieve report_category details',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}
async function getCategoriesByUser(userId) {
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/report-categories/by-user/${userId}`);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        //todo
    }
}

async function createCategories(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    data['reportCatRolesId'] = data.roles.map(() => uuidv4());
    data['reportCatTenantsId'] = data.tenants.map(() => uuidv4());

    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/report-categories', data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            await db.userDB.put({
                _id: data.Id,
                from: 'offline',
                reportCategory: data,
            });
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'reportCatgory',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'Report category successfully inserted',
                },
                data: {},
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to create report category',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function updateCategories(data) {
    data['reportCatRolesId'] = data.map(() => uuidv4());
    data['reportCatTenantsId'] = data.map(() => uuidv4());
    data['reporCatlinkId'] = data.map(() => uuidv4());

    if (navigator.onLine) {
        try {
            const res = await axios.put(`/api/report-categories/${data.id}`, data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            const doc = await db.userDB.get(data.id);
            await db.userDB.put(
                {
                    _id: data.id,
                    _rev: doc._rev,
                    from: 'offline',
                    reportCategory: data,
                },
                { force: true },
            );

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: data.id,
                    userId: helper.getUserId(),
                    entityType: 'reportCategory',
                    dateModified: DateTime.now().ToISO(),
                    changeType: 'UPDATE',
                },
            });

            return {
                //data: { activityTypes: types },
                result: {
                    success: true,
                    message: 'Report category updated successfully.',
                },
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to update report category',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function deleteCategories(data) {
    if (navigator.onLine) {
        const res = await axios.post(`/api/report-categories/delete`, {
            id: data,
        });
        return res.data;
    } else {
        try {
            const doc = await db.userDB.get(data);
            await db.userDB.remove(doc);
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: data,
                    entityType: 'Report',
                    userId: helper.getUserId(),
                    dateModified: DateTime.now().toISO(),
                    changeType: 'delete',
                },
            });
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to delete report',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}
